import type { GetChannelsQuery } from "#gql";

import { useQuery } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};

export type Channel = GetChannelsQuery["data"][number];
export default function useChannelsGet(options?: Options) {
  async function queryFn() {
    const res = await GqlGetChannels({});
    return res.data;
  }

  const context = useQuery({
    queryKey: [QUERY_KEYS.channels],
    queryFn
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
