<script lang="ts" setup>
const scrollWrapper = ref();
const document = ref();

const route = useRoute();
function scrollToDocument() {
  const rect = document.value.getBoundingClientRect();
  scrollWrapper.value?.scrollTo({ left: rect.left, behavior: "smooth" });
}
onMounted(() => {
  scrollToDocument();
});
watch(() => route.params.id, scrollToDocument);

usePageNavigation(document, "document");
const { data: channels } = useChannelsGet();

watch(channels, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    const hash = window.location.hash.substring(1); // Get the hash without the '#'
    if (hash && !currentChannel.value) {
      const channel = channels.value?.find((ch) => ch.name === hash);
      if (channel) {
        switchChannel(channel);
      }
    }
  }
});
const chatStore = useChatStore();
const currentChannel = ref<Channel | null>(null);

async function switchChannel(channel: Channel) {
  currentChannel.value = channel;
  chatStore.switchToRoom(channel.name);
}
const navigationStore = useNavigationStore();
navigationStore.pageTitle = "breadcrumb";

const { locale } = useI18n();
</script>

<template>
  <div
    ref="scrollWrapper"
    :class="
      cn(
        'h-[100dvh] w-full relative flex bg-neutral-1',
        'overflow-x-auto overflow-y-hidden xl:overflow-hidden',
        'max-xl:snap-x max-xl:snap-mandatory',
        'max-xl:py-12'
      )
    "
  >
    <Navigation />
    <div
      ref="document"
      :class="
        cn(
          'w-[100vw] sm:w-[50vw] md:w-[25vw] xl:w-[20rem]',
          'flex flex-col relative shrink-0',
          'snap-start h-full border-r border-neutral-4 bg-neutral-1'
        )
      "
    >
      <div
        :class="cn('sticky z-20 top-0 h-12 border-b', 'flex items-center gap-3 shrink-0 bg-neutral-0 p-2.5')"
      >
        <h2 class="h2">{{ $t("forum.channels") }}</h2>
      </div>
      <div id="forum-channels" class="grow scroll-area">
        <ul class="flex flex-col">
          <li v-for="channel in channels" :key="channel.name">
            <NuxtLink
              :id="channel.name"
              :to="`#${channel.name}`"
              :class="
                cn('grid grid-cols-5 gap-2.5 p-2.5 border-b hover:bg-neutral-0 transition-colors', {
                  'bg-neutral-0': currentChannel?.name === channel.name
                })
              "
              @click="switchChannel(channel)"
            >
              <img class="rounded-md" src="/public/correctiv-logo.png" alt="Channel 1" />
              <div class="col-span-4 flex flex-col gap-1">
                <p class="text-base font-semibold">{{ channel.labelDe }}</p>
                <p class="text-sm text-neutral-5">{{ channel.descriptionDe }}</p>
              </div>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <ChatRoom
      v-if="chatStore.currentRoom"
      :title="currentChannel?.[locale === 'de' ? 'labelDe' : 'labelEn']"
      class="max-md:w-[100vw] md:max-xl:w-[75vw]"
    />
  </div>
</template>
